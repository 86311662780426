import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';
import { Observable, catchError, retry } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MetadataService extends AbstractService {

    constructor(private httpClient: HttpClient, authService: AuthService) {
        super(authService);
    }

    public getCountries(): Observable<string[]> {
        return this.httpClient.get<string[]>(environment.apiUrl + '/metadata/countries', this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }
}
